@import './management/_variables.scss';
@import './management/_color-scheme';

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  line-height: 1.65;
  font-size: 1rem;
  /*font-family: 'Nanum Gothic', sans-serif;*/
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
}

* {
  /* Add !important to overwrite all elements */
  font-family: 'Nunito', sans-serif !important;
}

h1, h2, h3, h4, h5, h6,
b, strong,
.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 900;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.text-italic {
  font-style: italic;
}

a,
a:link,
a:hover,
a:visited,
a:active,
a:focus {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

a,
a *,
button, input
.transition {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

button:focus {
  outline: none;
}

.full-height {
  height: 100%;
}

.height-0 {
  height: 0;
}

.full-width {
  width: 100%;
}

.width-75 {
  width: 75%;
}

.width-50 {
  width: 50%;
}

.width-25 {
  width: 25%;
}

*[class^='mat'] {
  /*font-family: 'Muli', sans-serif;*/
  font-family: 'Nunito', sans-serif;
}

.nav-link.icon-item {
  font-size: 1.35rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  &:hover,
  &:active {
    color: $primary-color !important;
    cursor: pointer;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.custom-button {
  border-radius: 2rem !important;
  padding: 0 1.875rem !important;
}

.export-button {
  border: 1px solid $burnt-sienna !important;
  color: $burnt-sienna;
}

.custom-small-button {
  border-radius: 2rem !important;
  line-height: 1.25rem !important;
  padding: 0 0.625rem !important;
  font-size: small;

  &.text-eastern-blue {
    border: 0.063rem solid $eastern-blue !important;
  }

  &.text-burnt-sienna {
    border: 0.063rem solid $burnt-sienna !important;
  }

  &.text-dark-blue {
    border: 0.063rem solid $dark-blue !important;
  }
}

.custom-medium-button {
  border-radius: 2rem !important;
  line-height: 1.5rem !important;
  padding: 0 1.25rem !important;
  font-size: medium;

  &.text-eastern-blue {
    border: 0.063rem solid $eastern-blue !important;
  }

  &.text-burnt-sienna {
    border: 0.063rem solid $burnt-sienna !important;
  }

  &.text-dark-blue {
    border: 0.063rem solid $dark-blue !important;
  }
}

.custom-large-button {
  border-radius: 2rem !important;
  line-height: 3rem !important;
  padding: 0 2.5rem !important;
  font-size: large;

  &.text-eastern-blue {
    border: 0.063rem solid $eastern-blue !important;
  }
  &.text-burnt-sienna {
    border: 0.063rem solid $burnt-sienna !important;
  }
  &.text-dark-blue {
    border: 0.063rem solid $dark-blue !important;
  }
}

.text-header-cell {
  font-weight: bold;
  color: $dark;
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

h4 {
  font-weight: 700;
  font-size: 1.25rem;
  position: relative;

  span {
    position: relative;
    display: inline-block;
    z-index: 10;
  }
}

h5 {
  font-weight: 600;
  font-size: 1rem;
  position: relative;

  span {
    position: relative;
    display: inline-block;
    z-index: 10;
  }
}

h6 {
  font-weight: 500;
  font-size: 0.75rem;
  position: relative;

  span {
    position: relative;
    display: inline-block;
    z-index: 10;
  }
}

.list-mat-card-content {
  height: calc(100vh - 16rem);
  overflow-y: auto;
}

.edit-content {
  height: calc(100vh - 10rem);
  overflow-y: auto;
  overflow-x: hidden;
  /*padding-right: 0.625rem;*/
  padding: 0.5rem;
}

.appointments {
  max-width: 25rem;
  margin: 0 auto !important;
  float: none !important;
}

.appointments-header, .appointments-content, .appointments-footer {
  max-width: 25rem;
  margin: 0 auto;
}

.appointments-header {
  padding: 1.25rem 1.25rem 0;
}

/*.appointments-content {
  height: calc(100vh - 15.5rem);
  overflow-y: auto;
  padding: 1rem 1.25rem 0;
  max-width: 25rem;
  margin: auto;
}*/

.appointments-content {
  overflow-y: auto;
  padding: 1rem 1.25rem 0;
}

.appointments-content.footer-00 {
  height: calc(100vh - 7.5rem);
}

.appointments-content.footer-0 {
  height: calc(100vh - 8.8rem);
}

.appointments-content.footer-1 {
  height: calc(100vh - 11rem);
}

.appointments-content.footer-2 {
  height: calc(100vh - 13.3rem);
}

.appointments-content.footer-3 {
  height: calc(100vh - 15.5rem);
}

/*.appointments-footer {
  position: fixed;
  bottom: 0;*/
  /*width: 100%;*/
  /*padding: 0 1.25rem 1.25rem;
}*/

.appointments-footer {
  padding: 0 1.25rem 1.25rem;
}

.logo {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
}

.small-logo {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: solid 0.063rem rgba(0, 0, 0, 0.2);
}

.underline {
  text-decoration: underline !important;
}

.booking-logo {
  overflow: auto;
  display: block;
  position: absolute;
  top: -15px;
  left: -15px;
  z-index: 9999;
}

.booking-close {
  overflow: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 345px;
  z-index: 9999;
  cursor: pointer;
  color: $primary-color;
}

.booking-items {
  max-height: calc(100vh - 11rem - 12.15rem);
  overflow-y: auto;
}

.booking-items mat-card {
  cursor: pointer;
}

.booking-items mat-card.busy {
  cursor: default;
}

mat-card.active {
  background-color: $eastern-blue;
}

mat-card.busy {
  background-color: $primary-color;
}

mat-card.active h5, mat-card.active h6, mat-card.busy h5, mat-card.busy h6 {
  color: #ffffff;
}

.center-vert-hor {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

a.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

/* start scrollbar style */
/* width */
::-webkit-scrollbar {
  width: $scrollbar-width;
}

/* Track */
::-webkit-scrollbar-track {
  /*background: #f1f1f1;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 10px;
  /*background-color: rgba(243, 247, 249, 0.5);*/
  border: 1px solid rgb(255, 255, 255);
  background-clip: padding-box;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #717171;
}

/* end scrollbar style */

.row-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.font-size-18 {
  font-size: 18px;
}

#appointments {
  overflow: hidden;

  #header {
    /*height: $apps-header-height;*/
    height: 12%;
    width: 100%;
    background-color: #707070;
    color: #FFFFFF;

    .row {
      height: $apps-header-height;
    }

    #back {
      cursor: pointer;
    }
  }

  #body {
    padding: 1rem;

    #step {
      .logo {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        box-shadow: 0px 1px 5px 1px #00000029;
      }

      .item {
        cursor: pointer;
        background-color: #FAFAFA;
        padding: 0.313rem;
        border: 1px solid #005572;
        border-radius: 0.313rem;

        h4 {
          color: $eastern-blue;
          margin: 0;
          font-weight: 600;
        }

        h6 {
          color: $eastern-blue;
          margin: 0;
          font-weight: 400;
        }
      }
    }
  }

  h5 {
    color: $dark-blue;
  }

  .logo {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
  }

  .fixapp-logo {
    height: $apps-footer-logo-height;
    width: 7rem;
    cursor: pointer;
  }
}

#appointments.account {
  .logo {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
  }
}

.centered {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.height-flex {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.height-flex-grow {
  flex-grow: 1;
  overflow-x: hidden;
}

/* start cdkDropList style */

.drag-drop-list {
  /*width: 500px;
  max-width: 100%;*/
  width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.drag-drop-box {
  padding: 0.5rem;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-box:last-child {
  border: none;
}

.drag-drop-list.cdk-drop-list-dragging .drag-drop-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-box.cdk-drag-disabled {
  background: #e0e0e0;
  cursor: default;
}

.selected.drag-drop-box {
  background-color: $burnt-sienna;
  color: white;
}

/* end cdkDropList style */


/* start mat-checkbox style */

.mat-checkbox-checked.mat-accent {
  .mat-checkbox-ripple .mat-ripple-element {
    background-color: $eastern-blue !important;
  }

  .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $eastern-blue !important;
  }
}

.mat-checkbox.mat-accent {
  .mat-checkbox-ripple .mat-ripple-element {
    background-color: $eastern-blue !important;
  }
}

/*.availability {
  .mat-checkbox-ripple {
    .mat-ripple-element {
      background: $burnt-sienna !important;
    }
  }

  .mat-checkbox-checked {
    .mat-checkbox-background {
      background: $burnt-sienna !important;
    }

    .mat-checkbox-ripple {
      .mat-ripple-element {
        background: $burnt-sienna !important;
      }
    }
  }
}*/

.availability.mat-checkbox-ripple {
  .mat-ripple-element {
    background: $burnt-sienna !important;
  }
}

.availability.mat-checkbox-checked {
  .mat-checkbox-background {
    background: $burnt-sienna !important;
  }

  .mat-checkbox-ripple {
    .mat-ripple-element {
      background: $burnt-sienna !important;
    }
  }
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $spindle !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $eastern-blue !important;
}

/* end mat-checkbox style */

/* start mat-tabs style */

.mat-tab-label.mat-ripple.mat-tab-label-active {
  color: $burnt-sienna !important;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $burnt-sienna !important;
}

.mat-tab-label.mat-ripple {
  color: $dark-blue !important;
}

.mat-tab-body-wrapper {
  margin-top: 0.5rem;
}

/* end mat-tabs style */

.divider {
  border-bottom: 1px solid $gray-medium;
}

.description {
  max-height: 6rem;
}


/* end size style */

.w-10 {
  width: 10%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-90 {
  width: 90%;
}

/* end size style */

mat-card.dialog {
  box-shadow: none !important;
  padding: 0;
}

.shadow {
  box-shadow: 0 0 1rem rgba(0,0,0,.15) !important;
}

.resp-content {
  max-width: 35rem;
  margin: auto;
}

.pr-6 {
    padding-right: 4rem;
}
