$white: #FFFFFF;
$darker: #1C2428;
$dark: #37474F;
$gray-light: #EBF1F4;
$gray-medium: #e0e0e0;
$gray-dark: #8B9EA7;
$primary-color: #FF4081;
$accent-color: #2196F3;
$accent-color-2: #1AC673;
$error: #FF4100;
$burnt-sienna: #EE7D5A;
$spindle: #C8E5EF;
$eastern-blue: #1897C3;
$wild-strawberry: #FF4081;
$warn: #f44336;
$dark-blue: #2a6377;

.text-darker {
  color: $darker;
}

.bkg-darker {
  background-color: $darker;
}

.text-dark {
  color: $dark;
}

.bkg-dark {
  background-color: $dark;
}

.text-lighter {
  color: $gray-light;
}

.bkg-lighter {
  background-color: $gray-light;
}

.text-light {
  color: $gray-dark;
}

.bkg-light {
  background-color: $gray-dark;
}

.text-white {
  color: $white;
}

.bkg-white {
  background-color: $white;
}

.text-primary {
  color: $primary-color;
}

.bkg-primary {
  background-color: $primary-color;
}

.text-accent {
  color: $accent-color;
}

.bkg-accent {
  background-color: $accent-color;
}

.text-gray-dark {
  color: $gray-dark;
}

.bkg-spindle {
  background-color: $spindle;
}

.bkg-eastern-blue {
  background-color: $eastern-blue;
}

.text-eastern-blue {
  color: $eastern-blue;
}

.bkg-burnt-sienna {
  background-color: $burnt-sienna;
}

.text-burnt-sienna {
  color: $burnt-sienna;
}

.text-error {
  color: $error;
}

.text-warn {
  color: $warn;
}

.bkg-warn {
  background-color: $warn;
}

.text-dark-blue {
  color: $dark-blue;
}

.bkg-dark-blue {
  background-color: $dark-blue;
}
