/*
  =================================================================================================
  general layout variables
  =================================================================================================
*/
$header-height: 60px;
$sidenav-modules-width: 5rem; /* main menu that contains the modules: console, sms, email, etc */
$sidenav-menu-width: 12rem; /* secondary menu that contains the menu for each module */
$sidenav-container-padding: 1rem 0rem;

$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;

$scrollbar-width: 5px;

$apps-header-height: 4rem;
$apps-footer-logo-height: 2rem;
$apps-footer-link-height: 1.5rem;

$office-info-height: 20rem;
$office-working-hours-height: 40rem;
$office-operators-services-height: 40rem;
